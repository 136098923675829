
.feedback {
  background: #1b2028;
  // background: url(assets/images/feedback-image.png) no-repeat 92% 12%, #1b2028;
  box-sizing: border-box;
  padding: 0px 88px 42px 88px;
  margin-top: 95px;
  @include max1050 {
    padding: 0px 25px 42px 25px;
  }
  @include max576 {
    padding-bottom: 65px;
    margin-top: 60px;
  }
  &__content {
    display: flex;
    @include max800 {
      flex-wrap: wrap;
    }
    img {
      position: relative;
      top: 35px;
      left: 30px;
      @include max1900 {
        height: 25vw;
        top: 75px;
      }
      @include max1450 {
        height: 24vw;
        top: 114px;
      }
      @include max1250 {
        height: 30vw;
        top: 65px;
        left: 65px;
      }
      @include max1050 {
        height: 35vw;
        left: 50px;
      }
      @include max800 {
        height: 50vw;
        left: 45px;
      }
      @include max576 {
        height: 55vw;
        left: -5px;
        top: 45px;
      }
    }
  }
  &__text {
    // @include max1450 {
    //   text-align: center;
    // }

    @include max1050 {
      width: 50%;
    }
    @include max800 {
      width: 100%;
    }
    h2 {
      color: #ffffff;
      padding-top: 92px;
      @include max1050 {
        padding-top: 49px;
      }
      @include max576 {
        font-size: 24px;
      }
    }
    p {
      color: #ffffff;
      line-height: 24px;
      margin-top: 16px;
      &:first-of-type {
        margin-top: 37px;
        @include max576 {
          margin-top: 10px;
        }
      }
    }
  }
  &__line {
    background: #5f6876;
    width: 100%;
    height: 1px;
    margin-top: 105px;
  }
  &__form {
    h3 {
      font-size: 24px;
      color: #ffffff;
      font-weight: 500;
      text-align: center;
      margin-top: 38px;
    }
    p {
      margin-top: 12px;
      line-height: 24px;
      text-align: center;
      color: #768092;
      @include max576 {
        margin-bottom: 12px;
      }
    }
    input[type="text"] {
      margin-top: 35px;
      margin-right: 4px;
      border: 1px solid #5f6876;
      color: #768092;
      width: 302px;
      background: transparent;
      &:focus {
        border: 1px solid $primary-color;
      }

      @include max1900 {
        width: 24%;
      }
      @include max1250 {
        width: 32%;
      }
      @include max1050 {
        width: 100%;
      }
      @include max576 {
        margin-top: 4px;
      }
    }
    .btn {
      width: 302px;
      @include max1900 {
        width: 24%;
      }
      @include max1250 {
        width: 98%;
      }
      @include max1050 {
        width: 100%;
        position: relative;
        top: 36px;
      }
      @include max576 {
        top: 55px;
      }
    }
  }
  &__check {
    display: flex;
    @include max1050 {
      position: relative;
      top: -75px;
    }
    @include max576 {
      top: -70px;
    }
    .form-checkbox {
      width: 15px;
      height: 15px;
      position: relative;
      margin: 12px 0px 0px 2px;
      background: #8b888c;
      label {
        width: 11px;
        height: 11px;
        cursor: pointer;
        position: absolute;
        left: 2px;
        top: 2px;
        background: #1b2028;
        &:after {
          content: "";
          width: 7px;
          height: 4px;
          position: absolute;
          top: 1px;
          left: 1px;
          border: 2px solid #8b888c;
          border-top: none;
          border-right: none;
          // background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }
        // &:hover::after {
        //   opacity: 0.3;
        // }
      }
      input[type="checkbox"] {
        visibility: hidden;
        &:checked + label:after {
          opacity: 1;
        }
      }
    }
    span {
      color: #8b888c;

      font-size: 13px;
      margin-top: 13px;
      margin-left: 11px;
    }
  }
}

// end feedback

.footer {
  flex-wrap: wrap;
  margin-top: 90px;

  &__map {
    position: relative;
    height: 475px;
    width: 100%;
    @include max576 {
      height: 576px;
    }
  }

  .container {
    position: relative;
  }
  &__contacts {
    background: #f8f8f8;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 38px 34px 38px 32px;
    // width: 364px;
    // height: 338px;
    position: absolute;
    top: -410px;
    right: 0;
    // @include max1450 {
    //   left: 50vw;
    // }
    h3 {
      font-size: 19px;
      color: $secondry-color;
      font-weight: 500;
    }
    @include max576 {
      top: -645px;
      padding-right: 19px;
      width: 100%;
    }
  }
  &__phone {
    margin-top: 13px;
    line-height: 24px;
    font-size: 15px;
    display: block;
    text-decoration: none;
    color: $secondry-color;
    &::before {
      content: "";
      display: inline-block;
      background: url(assets/images/footer-phone.svg) no-repeat;
      width: 23px;
      height: 23px;
      margin-right: 14px;
      position: relative;
      top: 7px;
    }
  }
  &__mail {
    margin-top: 13px;
    line-height: 24px;
    &::before {
      content: "";
      display: inline-block;
      background: url(assets/images/footer-email.svg) no-repeat;
      width: 23px;
      height: 23px;
      margin-right: 14px;
      position: relative;
      top: 7px;
    }
  }
  &__adress {
    margin-top: 13px;
    line-height: 24px;
    &::before {
      content: "";
      display: inline-block;
      background: url(assets/images/footer-location.svg) no-repeat;
      width: 23px;
      height: 23px;
      margin-right: 14px;
      position: relative;
      top: 7px;
    }
    span {
      padding-left: 37px;
    }
  }

  &__socials {
    display: flex;
    margin-top: 20px;
    a {
      width: 34px;
      height: 34px;
      display: block;
      margin-right: 16px;
    }
  }

  &__socials-insta {
    background: url("assets/images/insta-dark.svg") no-repeat;
  }
  &__socials-youtube {
    background: url("assets/images/youtube-dark.svg") no-repeat;
  }
  &__socials-whatsapp {
    background: url("assets/images/whatsapp-dark.svg") no-repeat;
  }
  &__socials-telegram {
    background: url("assets/images/telegram-dark.svg") no-repeat;
  }
  &__socials-email {
    background: url("assets/images/email-dark.svg") no-repeat;
  }

  &__feedback {
    margin-top: 28px;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    color: $primary-color;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }

  &__information {
    display: flex;
    justify-content: space-between;
    margin: 9px 0px;
    flex-wrap: wrap;
    a {
      font-size: 12px;
      color: $secondry-color;
      line-height: 22px;
      text-decoration: none;
      @include max576 {
        width: 100%;
        text-align: left !important;
      }
      &:last-child {
        @include max576 {
          text-align: right;
        }
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
