
$primary-color: #FE5F55;
$secondry-color:#1A161D;

$gray: #f2f2f2;

$gray: #f2f2f2;
$grayFont: #4A4A4A;



/*
 * Основные точки перехода
 * Идем от Десктопа к мобильникам
 */


// Устройства X-Small (портретные телефоны, менее 576 пикселей)
// Нет медиа-запроса для `xs`, так как это значение по умолчанию в Bootstrap


// Больше fullHD
@media (min-width: 1930px) {

}
@mixin min1930 {
  @media (min-width: 1930px) {
    @content;
  }
}


//Средние мониторы
@media (max-width: 1900px) {
}
@mixin max1900 {
  @media (max-width: 1900px) {
    @content;
  }
}

@media (max-width: 1600px) {
}
@mixin max1600 {
  @media (max-width: 1600px) {
    @content;
  }
}

// Ноутбуки  и квадратные мониторы и горизонтальные планшеты
@media (max-width: 1450px) {

}
@mixin max1450 {
  @media (max-width: 1450px) {
    @content;
  }
}

@media (max-width: 1400px) {

}

@mixin max1400 {
  @media (max-width: 1450px) {
    @content;
  }
}

@media (max-width: 1400px) {

}

@mixin max1370 {
  @media (max-width: 1370px) {
    @content;
  }
}


@media (max-width: 1300px) {

}
@mixin max1300 {
  @media (max-width: 1300px) {
    @content;
  }
}


/**
  * Планшеты горизонтальные
 */

@media (max-width: 1250px) {

}
@mixin max1250 {
  @media (max-width: 1250px) {
    @content;
  }
}

@media (max-width: 1200px) {

}
@mixin max1200 {
  @media (max-width: 1200px) {
    @content;
  }
}



// Планшеты вертикальные
@media (max-width: 1050px) {

}
@mixin max1050 {
  @media (max-width: 1050px) {
    @content;
  }
}



// Устройства Medium (планшеты, 768 пикселей и ниже)
@media (max-width: 800px) {

}
@mixin max800 {
  @media (max-width: 800px) {
    @content;
  }
}

// Устройства Medium (планшеты, 768 пикселей и ниже)
@media (max-width: 700px) {

}
@mixin max700 {
  @media (max-width: 700px) {
    @content;
  }
}



// Устройства Small Телефоны
@media (max-width: 576px) {

}
@mixin max576 {
  @media (max-width: 576px) {
    @content;
  }
}

@media (max-width: 340px) {

}
@mixin max340 {
  @media (max-width: 340px) {
    @content;
  }
}



