/**
 * Modals ($modals)
 */


/* 1. Ensure this sits above everything when visible */
.modal {
  position: fixed;
  z-index: 100000000; /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.modal-heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  text-align: center;
  margin-bottom: 36px;
}

.modal.is-visible {
  visibility: visible;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s;
}

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.modal-wrapper {
  position: absolute;
  z-index: 9999;

  padding: 46px;

  width: 393px;
  // height: 517px;

  box-sizing: border-box;

  background: #ffffff;
  box-shadow: 0px 0px 41px rgba(0, 0, 0, 0.25);
}

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0;
}

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1;
}

input[type="text"] {
  margin-bottom: 16px;
}

input[type="number"] {
  margin-bottom: 16px;
}

//
.close {
  background: url(../images/form-close-X.svg) no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
}
