@import "../config.scss";

.main-menu {
  &__menu-container {
    //width: 100%;
    padding: 20px 20px 189px 42px;
    position: fixed;
    z-index: 99999;
    background: #fff;
    width: calc(100vw - 290px);
    box-sizing: border-box;
    height: 100%;
    opacity: 0;
     visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s linear;
    }

    @include max1900 {
      padding-bottom: 79px;
    }
    @include max1600 {
      padding-bottom: 9px;
    }
    @include max1450 {
      padding-top: 5px;
      padding-left: 20px;
      padding-bottom: 0px;
      width: calc(100vw - 244px);
    }
    @include max1300 {
      z-index: 999999999;
      overflow: auto;
    }
    @include max1250 {
      padding-left: 42px;
      padding-top: 20px;
      width: 100vw;
    }
    @include max800 {
      padding-top: 0px;
      padding-left: 20px;
      width: 100%;

    }
    .close {
      @include max1450 {
        top: 16px;
      }
      @include max1250 {
        top: 20px;
      }
      @include max800 {
        top: 10px;
      }
    }
  }

  &__menu-heading {
    font-size: 15px;
    color: #000;
    margin-top: 16px;
  }

  &__menu-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
    padding-right: 40px;
    @include max1600 {
      padding-right: 0px;
    }
    @include max1450 {
      margin-top: 21px;
    }
    @include max1250 {
      flex-wrap: wrap;
      padding-right: 40px;
      margin-top: 33px;
    }
    @include max800 {
      padding-right: 0px;
    }
  }

  &__column {
    width: 364px;
    @include max1900 {
      width: 355px;
    }
    @include max1600 {
      width: 285px;
    }
    @include max1450 {
      width: 24%;
    }
    @include max1250 {
      width: 48%;
      // &:nth-child(3) {
      //     margin-top: -135px;
      // }
    }
    @include max576 {
      width: 100%;
    }
  }

  &__product-item {
    height: 72px;
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 62px;
    @include max1600 {
      margin-bottom: 19px;
    }
    @include max1450 {
      height: 60px;
      margin-bottom: 17px;
    }
    @include max1250 {
      height: 72px;
      margin-bottom: 19px;
    }
    &.with-list {
      height: auto;
      padding: 27px 0px 27px 24px;
      box-sizing: border-box;
      display: block;
      @include max1450 {
        padding-top: 20px;
        padding-bottom: 22px;
        padding-left: 20px;
      }
      @include max1250 {
        padding-top: 24px;
        padding-left: 24px;
        padding-bottom: 30px;
      }
    }
    &.in-dev {
      border: none;
      background: #f2f2f2;
    }
  }

  &__in-dev-note {
    display: none;
    background: url(assets/images/main-menu/Union.svg) no-repeat;
    width: 176px;
    height: 65px;
    position: absolute;
    right: 12px;
    top: -28px;
    @include max1450 {
      top: -40px;
    }
    @include max1250 {
      top: -35px;
    }
    p {
      color: #fff;
      font-size: 12px;
      position: absolute;
      top: 22px;
      left: 27px;
    }
  }

  &__accordion {
    margin: 0px;
    ul {
      display: none;
      margin-top: 18px;
      margin-left: 43px;
    }
    a {
      display: inline-block;
      font-size: 15px;
      color: #000;
      opacity: 1;
      text-decoration: none;
      margin-bottom: 13px;
      transition: 0.125s;
      line-height: 20px;
      &:hover {
        color: $primary-color;
        font-weight: 400;
      }
    }
  }

  .accordion-item {
    text-decoration: none;
    cursor: pointer;
    color: #000;
    line-height: 15px;
    margin-top: 16px;
    margin-bottom: 0px;
    opacity: 1;
    font-size: 15px;
    @include max1450 {
      margin-top: 10px;
    }
    @include max1250 {
      margin-top: 16px;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 21px;
      position: relative;
      top: 7px;
    }
    &::after {
      content: "";
      display: inline-block;
      background: url(assets/images/main-menu/Vector-menu-down.svg) no-repeat;
      width: 8px;
      height: 5px;
      margin-left: 7px;
      position: relative;
      bottom: 1px;
    }
    &.active::after {
      content: "";
      display: inline-block;
      background: url(assets/images/main-menu/Vector-menu-up.svg) no-repeat;
      width: 8px;
      height: 5px;
      margin-left: 7px;
      position: relative;
      bottom: 1px;
    }
    &.ads {
      &::before {
        background: url(assets/images/main-menu/Megaphone-menu.svg) no-repeat;
        @include max800 {
          background: url(assets/images/Megaphone-mobile.svg) no-repeat;
        }
      }
    }
    &.ads.active {
      color: #bbbcbe;
      &::before {
        background: url(assets/images/main-menu/Megaphone-red.svg) no-repeat;
        @include max800 {
          background: url(assets/images/Megaphone-mobile.svg) no-repeat;
        }
      }
    }

    &.site {
      &::before {
        background: url(assets/images/main-menu/Wrench-menu.svg) no-repeat;
        @include max800 {
          background: url(assets/images/Wrench-mobile.svg) no-repeat;
        }
      }
    }
    &.site.active {
      color: #bbbcbe;
      &::before {
        background: url(assets/images/main-menu/Wrench-red.svg) no-repeat;
        @include max800 {
          background: url(assets/images/Wrench-mobile.svg) no-repeat;
        }
      }
    }

    &.smm {
      &::before {
        background: url(assets/images/main-menu/ThumbsUp-menu.svg) no-repeat;
        @include max800 {
          background: url(assets/images/ThumbsUp-mobile.svg) no-repeat;
        }
      }
    }
    &.smm.active {
      color: #bbbcbe;
      &::before {
        background: url(assets/images/main-menu/ThumbsUp-red.svg) no-repeat;
        @include max800 {
          background: url(assets/images/ThumbsUp-mobile.svg) no-repeat;
        }
      }
    }

    &.brand {
      &::before {
        background: url(assets/images/main-menu/flyers-menu.svg) no-repeat;
        @include max800 {
          background: url(assets/images/flyers-mobile.svg) no-repeat;
        }
      }
    }
    &.brand.active {
      color: #bbbcbe;
      &::before {
        background: url(assets/images/main-menu/flyers-red.svg) no-repeat;
        @include max800 {
          background: url(assets/images/flyers-mobile.svg) no-repeat;
        }
      }
    }

    &.video {
      &::before {
        background: url(assets/images/main-menu/brand-menu.svg) no-repeat;
        @include max800 {
          background: url(assets/images/brand-mobile.svg) no-repeat;
        }
      }
    }
    &.video.active {
      color: #bbbcbe;
      &::before {
        background: url(assets/images/main-menu/brand-red.svg) no-repeat;
        @include max800 {
          background: url(assets/images/brand-mobile.svg) no-repeat;
        }
      }
    }


    &.complex {
      &::before {
        background: url(assets/images/complex-white.svg) no-repeat;
        @include max1050 {
          background: url(assets/images/complex-red.svg.svg) no-repeat;
        }
      }
    }

    &.complex.active {
      color: #696161;
      opacity: 1;
      &::before {
        background: url(assets/images/complex-red.svg) no-repeat;
        @include max1050 {
          background: url(assets/images/complex-red.svg) no-repeat;
        }
      }
    }
  }

  &__product-name {
    font-weight: 500;
    color: #000;
    display: block;
    transition: 0.125s;
    line-height: 19px;
    @include max1600 {
      width: 92%;
    }
    @include max1450 {
      font-size: 15px;
    }
    @include max1250 {
      font-size: 16px;
    }
    &.with-icon {
      cursor: pointer;
      &::before {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 23px;
        left: 24px;
        @include max1450 {
          top: 18px;
        }
        @include max1250 {
          top: 23px;
        }
      }
      &:hover {
        color: $primary-color;
      }
    }
    &.bloggers {
      &::before {
        background: url(assets/images/main-menu/influencer.svg) no-repeat;
      }
    }
    &.freelance {
      &::before {
        background: url(assets/images/main-menu/freelance.svg) no-repeat;
      }
    }
    &.online-bank {
      &::before {
        background: url(assets/images/main-menu/bank.svg) no-repeat;
      }
    }
    &.cases-product {
      &::before {
        background: url(assets/images/main-menu/case.svg) no-repeat;
      }
    }
    &.employees {
      &::before {
        background: url(assets/images/main-menu/magnifier.svg) no-repeat;
      }
    }
    &.commercial {
      &::before {
        background: url(assets/images/main-menu/search.svg) no-repeat;
      }
    }
    &.supplier {
      &::before {
        background: url(assets/images/main-menu/case-study.svg) no-repeat;
      }
    }
    &.business-project {
      &::before {
        background: url(assets/images/main-menu/business.svg) no-repeat;
      }
    }
    &.crowdfunding {
      &::before {
        background: url(assets/images/main-menu/crowdfunding.svg) no-repeat;
      }
    }
    &.cleaning {
      &::before {
        background: url(assets/images/main-menu/delete-ico.svg) no-repeat;
      }
    }
    &.start-business {
      &::before {
        background: url(assets/images/main-menu/opportunity.svg) no-repeat;
      }
    }
    &.business-constructor {
      &::before {
        background: url(assets/images/main-menu/stairs.svg) no-repeat;
      }
    }
    &.online-accounting {
      &::before {
        background: url(assets/images/main-menu/calculator.svg) no-repeat;
      }
    }
  }

  &__sub-product {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: #000;
    line-height: 15px;
    margin-top: 25px;
    font-size: 15px;
    position: relative;
    margin-left: 45px;
    transition: 0.125s;
    @include max1450 {
      margin-top: 22px;
      width: 75%;
    }
    @include max1250 {
      margin-top: 25px;
    }
    &.not-active {
      opacity: 0.5;
    }
    &:hover {
      color: $primary-color;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: -5px;
      left: -42px;
    }
    &.mentoring {
      &::before {
        background: url(assets/images/main-menu/teacher.svg) no-repeat;
      }
    }
    &.education-courses {
      &::before {
        background: url(assets/images/main-menu/online-learning.svg) no-repeat;
      }
    }
    &.entrepreneur-inst {
      &::before {
        background: url(assets/images/main-menu/case-study.svg) no-repeat;
      }
    }
    &.knowledge {
      &::before {
        background: url(assets/images/main-menu/online-education.svg) no-repeat;
      }
    }


    &.business-taxi {
      &::before {
        background: url(assets/images/main-menu/taxi.svg) no-repeat;
      }
    }
    &.business-trucking {
      &::before {
        background: url(assets/images/main-menu/truck.svg) no-repeat;
      }
    }
    &.business-avia {
      &::before {
        background: url(assets/images/main-menu/airplane.svg) no-repeat;
      }
    }
    &.car-rent {
      &::before {
        background: url(assets/images/main-menu/car-key.svg) no-repeat;
      }
    }
  }



  /**
 *  Второй тип аккордиона
 */
  .main-menu__accordion2 .accordion-item {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: #000;
    line-height: 15px;
    margin-top: 25px;
    font-size: 15px;
    position: relative;
    margin-left: 45px;
    transition: 0.125s;
    @include max1450 {
      margin-top: 22px;
      width: 75%;
    }
    @include max1250 {
      margin-top: 25px;
    }
    &.not-active {
      opacity: 0.5;
    }
    &:hover {
      color: $primary-color;
    }
    &::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: -5px;
      left: -42px;
    }
    &:after {
      display: none;
    }



  }









  .crm-system {
    &::before {
      background: url(assets/images/main-menu/internet.svg) no-repeat;
    }
  }
  .telephony {
    &::before {
      background: url(assets/images/main-menu/telefoniya.svg) no-repeat;
    }
  }
  .call-tracking {
    &::before {
      background: url(assets/images/main-menu/calltracking.svg) no-repeat;
    }
  }
  .chatbot {
    &::before {
      background: url(assets/images/main-menu/chatbot.svg) no-repeat;
    }
  }
  .online-pay {
    &::before {
      background: url(assets/images/main-menu/online-pay.svg) no-repeat;
    }
  }
  .site-constructor {
    &::before {
      background: url(assets/images/main-menu/site-constructor.svg) no-repeat;
    }
  }

  .analytics {
    &::before {
      background: url(assets/images/main-menu/analytics.svg) no-repeat;
    }
  }


}
