@import "resets.scss";
@import "config.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

/**
 * PLugins
 */

@import "plugins/modal";




body {
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  color: #1a161d;
  opacity: 0;
}

// end body
h1 {
  font-size: 42px;
}

h2 {
  font-size: 36px;
  font-weight: 500;
  color: #000000;
  line-height: 50px;
}

p {
  font-size: 15px;
}

a {
  color: #fe5f55;
}

.section {
  display: flex;
  justify-content: center;
  margin-left: 290px;
  width: calc(100% - 290px);
  overflow-x: hidden;


  @include max1450 {
    width: calc(100% - 244px) ;
    margin-left: 244px;
  }
  @include max1050 {

    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }
}

.container {
  width: 1410px;
  //height: 1000px;

  @include max1900 {
    width: 93%;
  }

  @include max1450 {
    width: 94%;
  }

}

.btn {
  width: 245px;
  height: 50px;
  background: $primary-color;
  border: none;
  border-radius: 6px;

  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  cursor: pointer;

  //background: none;

  color: #fff;
  transition: 0.25s;
}

.btn:hover {
  box-shadow: inset 0 -3.25em 0 0 #e64137;
  color: #fff;
  border-color: #e64137;
}

.btn.outline {
  border: 1px solid #dadada;
  background: transparent;
  //color: #000;

  background: none;
  --color: #000;
  --hover: #1b2028;
  color: var(--color);
  transition: 0.25s;
}

.btn.outline:hover {
  border-color: var(--hover);
  color: #fff;
  box-shadow: inset 0 -3.25em 0 0 var(--hover);
}

.btn.outline.red {
  width: 353px;
  border: 1px solid $primary-color;
  margin-top: 50px;

  color: $primary-color;
  transition: 0.25s;
}

.btn.outline.red:hover {
  box-shadow: inset 0 -3.25em 0 0 $primary-color;
  color: #fff;
  border-color: $primary-color;
}

input[type="text"] {
  width: 100%;
  height: 50px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 120%;
  /* identical to box height, or 18px */
  padding: 0 20px;
  letter-spacing: 0.02em;

  color: #768092;

  &.validate-er {
    border: 1px solid $primary-color;
  }
}

input[type="number"] {
  width: 100%;
  height: 50px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 120%;
  /* identical to box height, or 18px */
  padding: 0 20px;
  letter-spacing: 0.02em;

  color: #768092;
}

.validate-er {
  border: 1px solid $primary-color !important;
}



// mobileHamburger
$bar-width: 25px;
$bar-height: 2px;
$bar-spacing: 7px;

.hamburger-menu {
  width: 44px;
  height: 44px;
  cursor: pointer;
  background: #333a45;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
}
.bar {
  top: 3px;
}
.bar,
.bar:after,
.bar:before {
  width: $bar-width;
  height: $bar-height;
  background: #93979d;
}
.bar {
  position: relative;
  transform: translateY($bar-spacing);
  //background: rgba(255, 255, 255, 1);
  transition: all 0ms 300ms;
  width: 14px;

  &.animate {
    background: rgba(255, 255, 255, 0);
  }
}
.bar:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  //background: rgba(255, 255, 255, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.bar:after {
  width: 18px;
  content: "";
  position: absolute;
  left: 0;
  top: $bar-spacing;
  //background: rgba(255, 255, 255, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.bar.animate:after {
  width: 25px;
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
  transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

// все продукты

.allproducts_btn {
  background: #333a45;
  width: 246px;
  height: 50px;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  // box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);

  &::before {
    content: "";
    display: inline-block;
    background: url(assets/images/ico.svg) no-repeat;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    position: relative;
    top: 3px;


  }
}


.radio-square {
  border: 1px solid #d8d8d8;
  margin-bottom: 16px;
  padding: 10px 18px;
  cursor: pointer;
  position: relative;
  padding-right: 56px;
  span {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border: 2px solid #d8d8d8;
    border-radius: 50%;
    position: absolute;
    right: 13px;
    top: 17px;
  }

  &.active {
    span {
      border: 2px solid #fe5f55;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span:before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      //position: absolute;
      //right: 17px;
      background: #fe5f55;
      //top: 20px;

    }
  }
}

/**
 * Паршилы
 */

@import "./partials/footer";
@import "./partials/all-products.scss";
@import "./partials/modals";





// 404 страница
.not-found-page {
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 49px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  margin-top: 60px;
  width: 900px;
  text-align: center;
  p {
    font-size: 100px;
    margin: 50px 0px 50px 0px;
  }
  a {
    color: $primary-color;
    font-size: 15px;
    &:hover {
      color: #ff5443;
      font-weight: 500;
    }
  }
}

/**
 * Поиск страница Search
 */

.search-page {
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 49px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  margin-top: 60px;
  width: 900px;
  text-align: center;
  p {
    font-size: 100px;
    margin: 50px 0px 50px 0px;
  }
  a {
    color: $primary-color;
    font-size: 15px;
    &:hover {
      color: #ff5443;
      font-weight: 500;
    }
  }
}


