@import "../config";
.modal{
  .btn {
    width: 100%;
    margin-top: 20px;
  }
  // checkbox light
  .check {
    display: flex;
    .form-checkbox-light {
      width: 15px;
      height: 15px;
      position: relative;
      margin-left: 9px;
      background: #000000;

      &.validate-er {
        background: $primary-color;
      }

      &>label {
        width: 11px;
        height: 11px;
        cursor: pointer;
        position: absolute;
        left: 2px;
        top: 2px;
        background: #ffffff;
        &:after {
          content: "";
          width: 7px;
          height: 4px;
          position: absolute;
          top: 1px;
          left: 1px;
          border: 3px solid #000;
          border-top: none;
          border-right: none;
          // background: transparent;
          opacity: 0;
          transform: rotate(-45deg);
        }
        // &:hover::after {
        //   opacity: 0.3;
        // }
      }
      &>input[type="checkbox"] {
        visibility: hidden;
        &:checked + label:after {
          opacity: 1;
        }
      }
    }
    span {
      color: #000000;
      font-size: 13px;
      margin-left: 11px;
      line-height: 15px;
    }
  }
  .validate-er {
    border: 1px solid $primary-color;
  }

  .validate-msg {
    font-size: 15px;
    line-height: 32px;
    letter-spacing: .01em;
    margin-top: -15px;
    margin-bottom: 13px;
    margin-left: 0px;
    color: $primary-color;
  }


  .modal-success {
    display: none;
    font-weight: normal;
    font-size: 15px;
    line-height: 150%;
    text-align: center;
    /* or 22px */
    .btn {
      width: 116px;
    }
  }

  .modal-text {
    font-size: 16px;
    font-family: 'Roboto';
    line-height: 24px;
    text-align: center;
  }
}



.modal-success {
  display: none;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
  /* or 22px */
  .btn {
    width: 116px;
  }
}

// .validate-er {
//   border: 1px solid $primary-color;
// }

#video-modal {
  //position: relative;
  .modal-wrapper {
    width: 960px;
    height: 632px;
    top: 13vh;

    @include max800  {
      width: 96%;

      height: 56vw;
      padding: 20px;
      top: 40vh;

    }

    @include max576 {
      width: 96%;

      height: 56vw;
      padding: 20px;
      top: 40vh;
      padding: 20px;
    }



  }

  .modal-header {
    @include max576 {
      display: none;
    }
  }


}

.youtube-btn {
  width: 60px;
  height: 44px;
  background: url(assets/images/youtube-btn.svg);
  background-size: cover;
  display: block;
  position: absolute;
  left: 38%;
  top: 33%;




  @include max800 {
    width: 34px;
    height: 26px;
  }

}


#spasibo {
  .modal-success {
    display:block;
  }
  .btn {
    margin-top: 20px;
  }
}

/**
 * Модалка формы оплаты
 */
.tarif-modal {

  .modal-wrapper {
    @include max576 {
      width: 96%;
      height: 87Vh;
      overflow-y: auto;
    }
  }

  label {
    width: 100%;
    display:block;
  }

  &__price{
    margin-top: 10px;
    text-align: center;
  }
  &__dogovor  {
    margin-top: 10px;
    text-align: center;
    width: 100%;
    display: block;
  }
  &__type-pay{
    margin-top: 20px;

  }

}




