@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input:focus {
  outline: none; }

* {
  -webkit-appearance: none; }

textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0; }

/*
 * Основные точки перехода
 * Идем от Десктопа к мобильникам
 */
/**
  * Планшеты горизонтальные
 */
/**
 * PLugins
 */
/**
 * Modals ($modals)
 */
/* 1. Ensure this sits above everything when visible */
.modal {
  position: fixed;
  z-index: 100000000;
  /* 1 */
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.modal-heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
  text-align: center;
  margin-bottom: 36px; }

.modal.is-visible {
  visibility: visible; }

.modal-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.3s, opacity 0.3s; }

.modal.is-visible .modal-overlay {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s; }

.modal-wrapper {
  position: absolute;
  z-index: 9999;
  padding: 46px;
  width: 393px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 41px rgba(0, 0, 0, 0.25); }

.modal-transition {
  transition: all 0.3s 0.12s;
  transform: translateY(-10%);
  opacity: 0; }

.modal.is-visible .modal-transition {
  transform: translateY(0);
  opacity: 1; }

input[type="text"] {
  margin-bottom: 16px; }

input[type="number"] {
  margin-bottom: 16px; }

.close {
  background: url(../images/form-close-X.svg) no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px; }
  .close:hover {
    cursor: pointer; }

body {
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  color: #1a161d;
  opacity: 0; }

h1 {
  font-size: 42px; }

h2 {
  font-size: 36px;
  font-weight: 500;
  color: #000000;
  line-height: 50px; }

p {
  font-size: 15px; }

a {
  color: #fe5f55; }

.section {
  display: flex;
  justify-content: center;
  margin-left: 290px;
  width: calc(100% - 290px);
  overflow-x: hidden; }
  @media (max-width: 1450px) {
    .section {
      width: calc(100% - 244px);
      margin-left: 244px; } }
  @media (max-width: 1050px) {
    .section {
      max-width: 100%;
      width: 100%;
      margin-left: 0; } }

.container {
  width: 1410px; }
  @media (max-width: 1900px) {
    .container {
      width: 93%; } }
  @media (max-width: 1450px) {
    .container {
      width: 94%; } }

.btn {
  width: 245px;
  height: 50px;
  background: #FE5F55;
  border: none;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  color: #fff;
  transition: 0.25s; }

.btn:hover {
  box-shadow: inset 0 -3.25em 0 0 #e64137;
  color: #fff;
  border-color: #e64137; }

.btn.outline {
  border: 1px solid #dadada;
  background: transparent;
  background: none;
  --color: #000;
  --hover: #1b2028;
  color: var(--color);
  transition: 0.25s; }

.btn.outline:hover {
  border-color: var(--hover);
  color: #fff;
  box-shadow: inset 0 -3.25em 0 0 var(--hover); }

.btn.outline.red {
  width: 353px;
  border: 1px solid #FE5F55;
  margin-top: 50px;
  color: #FE5F55;
  transition: 0.25s; }

.btn.outline.red:hover {
  box-shadow: inset 0 -3.25em 0 0 #FE5F55;
  color: #fff;
  border-color: #FE5F55; }

input[type="text"] {
  width: 100%;
  height: 50px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 120%;
  /* identical to box height, or 18px */
  padding: 0 20px;
  letter-spacing: 0.02em;
  color: #768092; }
  input[type="text"].validate-er {
    border: 1px solid #FE5F55; }

input[type="number"] {
  width: 100%;
  height: 50px;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 120%;
  /* identical to box height, or 18px */
  padding: 0 20px;
  letter-spacing: 0.02em;
  color: #768092; }

.validate-er {
  border: 1px solid #FE5F55 !important; }

.hamburger-menu {
  width: 44px;
  height: 44px;
  cursor: pointer;
  background: #333a45;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box; }

.bar {
  top: 3px; }

.bar,
.bar:after,
.bar:before {
  width: 25px;
  height: 2px;
  background: #93979d; }

.bar {
  position: relative;
  transform: translateY(7px);
  transition: all 0ms 300ms;
  width: 14px; }
  .bar.animate {
    background: rgba(255, 255, 255, 0); }

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 7px;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.bar:after {
  width: 18px;
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.bar.animate:after {
  width: 25px;
  top: 0;
  transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.bar.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.allproducts_btn {
  background: #333a45;
  width: 246px;
  height: 50px;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 15px;
  font-family: "Roboto", sans-serif; }
  .allproducts_btn::before {
    content: "";
    display: inline-block;
    background: url(assets/images/ico.svg) no-repeat;
    width: 16px;
    height: 16px;
    margin-right: 16px;
    position: relative;
    top: 3px; }

.radio-square {
  border: 1px solid #d8d8d8;
  margin-bottom: 16px;
  padding: 10px 18px;
  cursor: pointer;
  position: relative;
  padding-right: 56px; }
  .radio-square span {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border: 2px solid #d8d8d8;
    border-radius: 50%;
    position: absolute;
    right: 13px;
    top: 17px; }
  .radio-square.active span {
    border: 2px solid #fe5f55;
    display: flex;
    justify-content: center;
    align-items: center; }
  .radio-square.active span:before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #fe5f55; }

/**
 * Паршилы
 */
.feedback {
  background: #1b2028;
  box-sizing: border-box;
  padding: 0px 88px 42px 88px;
  margin-top: 95px; }
  @media (max-width: 1050px) {
    .feedback {
      padding: 0px 25px 42px 25px; } }
  @media (max-width: 576px) {
    .feedback {
      padding-bottom: 65px;
      margin-top: 60px; } }
  .feedback__content {
    display: flex; }
    @media (max-width: 800px) {
      .feedback__content {
        flex-wrap: wrap; } }
    .feedback__content img {
      position: relative;
      top: 35px;
      left: 30px; }
      @media (max-width: 1900px) {
        .feedback__content img {
          height: 25vw;
          top: 75px; } }
      @media (max-width: 1450px) {
        .feedback__content img {
          height: 24vw;
          top: 114px; } }
      @media (max-width: 1250px) {
        .feedback__content img {
          height: 30vw;
          top: 65px;
          left: 65px; } }
      @media (max-width: 1050px) {
        .feedback__content img {
          height: 35vw;
          left: 50px; } }
      @media (max-width: 800px) {
        .feedback__content img {
          height: 50vw;
          left: 45px; } }
      @media (max-width: 576px) {
        .feedback__content img {
          height: 55vw;
          left: -5px;
          top: 45px; } }
  @media (max-width: 1050px) {
    .feedback__text {
      width: 50%; } }
  @media (max-width: 800px) {
    .feedback__text {
      width: 100%; } }
  .feedback__text h2 {
    color: #ffffff;
    padding-top: 92px; }
    @media (max-width: 1050px) {
      .feedback__text h2 {
        padding-top: 49px; } }
    @media (max-width: 576px) {
      .feedback__text h2 {
        font-size: 24px; } }
  .feedback__text p {
    color: #ffffff;
    line-height: 24px;
    margin-top: 16px; }
    .feedback__text p:first-of-type {
      margin-top: 37px; }
      @media (max-width: 576px) {
        .feedback__text p:first-of-type {
          margin-top: 10px; } }
  .feedback__line {
    background: #5f6876;
    width: 100%;
    height: 1px;
    margin-top: 105px; }
  .feedback__form h3 {
    font-size: 24px;
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    margin-top: 38px; }
  .feedback__form p {
    margin-top: 12px;
    line-height: 24px;
    text-align: center;
    color: #768092; }
    @media (max-width: 576px) {
      .feedback__form p {
        margin-bottom: 12px; } }
  .feedback__form input[type="text"] {
    margin-top: 35px;
    margin-right: 4px;
    border: 1px solid #5f6876;
    color: #768092;
    width: 302px;
    background: transparent; }
    .feedback__form input[type="text"]:focus {
      border: 1px solid #FE5F55; }
    @media (max-width: 1900px) {
      .feedback__form input[type="text"] {
        width: 24%; } }
    @media (max-width: 1250px) {
      .feedback__form input[type="text"] {
        width: 32%; } }
    @media (max-width: 1050px) {
      .feedback__form input[type="text"] {
        width: 100%; } }
    @media (max-width: 576px) {
      .feedback__form input[type="text"] {
        margin-top: 4px; } }
  .feedback__form .btn {
    width: 302px; }
    @media (max-width: 1900px) {
      .feedback__form .btn {
        width: 24%; } }
    @media (max-width: 1250px) {
      .feedback__form .btn {
        width: 98%; } }
    @media (max-width: 1050px) {
      .feedback__form .btn {
        width: 100%;
        position: relative;
        top: 36px; } }
    @media (max-width: 576px) {
      .feedback__form .btn {
        top: 55px; } }
  .feedback__check {
    display: flex; }
    @media (max-width: 1050px) {
      .feedback__check {
        position: relative;
        top: -75px; } }
    @media (max-width: 576px) {
      .feedback__check {
        top: -70px; } }
    .feedback__check .form-checkbox {
      width: 15px;
      height: 15px;
      position: relative;
      margin: 12px 0px 0px 2px;
      background: #8b888c; }
      .feedback__check .form-checkbox label {
        width: 11px;
        height: 11px;
        cursor: pointer;
        position: absolute;
        left: 2px;
        top: 2px;
        background: #1b2028; }
        .feedback__check .form-checkbox label:after {
          content: "";
          width: 7px;
          height: 4px;
          position: absolute;
          top: 1px;
          left: 1px;
          border: 2px solid #8b888c;
          border-top: none;
          border-right: none;
          opacity: 0;
          transform: rotate(-45deg); }
      .feedback__check .form-checkbox input[type="checkbox"] {
        visibility: hidden; }
        .feedback__check .form-checkbox input[type="checkbox"]:checked + label:after {
          opacity: 1; }
    .feedback__check span {
      color: #8b888c;
      font-size: 13px;
      margin-top: 13px;
      margin-left: 11px; }

.footer {
  flex-wrap: wrap;
  margin-top: 90px; }
  .footer__map {
    position: relative;
    height: 475px;
    width: 100%; }
    @media (max-width: 576px) {
      .footer__map {
        height: 576px; } }
  .footer .container {
    position: relative; }
  .footer__contacts {
    background: #f8f8f8;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 38px 34px 38px 32px;
    position: absolute;
    top: -410px;
    right: 0; }
    .footer__contacts h3 {
      font-size: 19px;
      color: #1A161D;
      font-weight: 500; }
    @media (max-width: 576px) {
      .footer__contacts {
        top: -645px;
        padding-right: 19px;
        width: 100%; } }
  .footer__phone {
    margin-top: 13px;
    line-height: 24px;
    font-size: 15px;
    display: block;
    text-decoration: none;
    color: #1A161D; }
    .footer__phone::before {
      content: "";
      display: inline-block;
      background: url(assets/images/footer-phone.svg) no-repeat;
      width: 23px;
      height: 23px;
      margin-right: 14px;
      position: relative;
      top: 7px; }
  .footer__mail {
    margin-top: 13px;
    line-height: 24px; }
    .footer__mail::before {
      content: "";
      display: inline-block;
      background: url(assets/images/footer-email.svg) no-repeat;
      width: 23px;
      height: 23px;
      margin-right: 14px;
      position: relative;
      top: 7px; }
  .footer__adress {
    margin-top: 13px;
    line-height: 24px; }
    .footer__adress::before {
      content: "";
      display: inline-block;
      background: url(assets/images/footer-location.svg) no-repeat;
      width: 23px;
      height: 23px;
      margin-right: 14px;
      position: relative;
      top: 7px; }
    .footer__adress span {
      padding-left: 37px; }
  .footer__socials {
    display: flex;
    margin-top: 20px; }
    .footer__socials a {
      width: 34px;
      height: 34px;
      display: block;
      margin-right: 16px; }
  .footer__socials-insta {
    background: url("assets/images/insta-dark.svg") no-repeat; }
  .footer__socials-youtube {
    background: url("assets/images/youtube-dark.svg") no-repeat; }
  .footer__socials-whatsapp {
    background: url("assets/images/whatsapp-dark.svg") no-repeat; }
  .footer__socials-telegram {
    background: url("assets/images/telegram-dark.svg") no-repeat; }
  .footer__socials-email {
    background: url("assets/images/email-dark.svg") no-repeat; }
  .footer__feedback {
    margin-top: 28px;
    font-size: 15px;
    font-weight: 500;
    display: inline-block;
    color: #FE5F55;
    text-decoration: underline; }
    .footer__feedback:hover {
      cursor: pointer; }
  .footer__information {
    display: flex;
    justify-content: space-between;
    margin: 9px 0px;
    flex-wrap: wrap; }
    .footer__information a {
      font-size: 12px;
      color: #1A161D;
      line-height: 22px;
      text-decoration: none; }
      @media (max-width: 576px) {
        .footer__information a {
          width: 100%;
          text-align: left !important; } }
      @media (max-width: 576px) {
        .footer__information a:last-child {
          text-align: right; } }
      .footer__information a:hover {
        cursor: pointer; }

/*
 * Основные точки перехода
 * Идем от Десктопа к мобильникам
 */
/**
  * Планшеты горизонтальные
 */
.main-menu {
  /**
 *  Второй тип аккордиона
 */ }
  .main-menu__menu-container {
    padding: 20px 20px 189px 42px;
    position: fixed;
    z-index: 99999;
    background: #fff;
    width: calc(100vw - 290px);
    box-sizing: border-box;
    height: 100%;
    opacity: 0;
    visibility: hidden; }
    .main-menu__menu-container.active {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.5s linear; }
    @media (max-width: 1900px) {
      .main-menu__menu-container {
        padding-bottom: 79px; } }
    @media (max-width: 1600px) {
      .main-menu__menu-container {
        padding-bottom: 9px; } }
    @media (max-width: 1450px) {
      .main-menu__menu-container {
        padding-top: 5px;
        padding-left: 20px;
        padding-bottom: 0px;
        width: calc(100vw - 244px); } }
    @media (max-width: 1300px) {
      .main-menu__menu-container {
        z-index: 999999999;
        overflow: auto; } }
    @media (max-width: 1250px) {
      .main-menu__menu-container {
        padding-left: 42px;
        padding-top: 20px;
        width: 100vw; } }
    @media (max-width: 800px) {
      .main-menu__menu-container {
        padding-top: 0px;
        padding-left: 20px;
        width: 100%; } }
    @media (max-width: 1450px) {
      .main-menu__menu-container .close {
        top: 16px; } }
    @media (max-width: 1250px) {
      .main-menu__menu-container .close {
        top: 20px; } }
    @media (max-width: 800px) {
      .main-menu__menu-container .close {
        top: 10px; } }
  .main-menu__menu-heading {
    font-size: 15px;
    color: #000;
    margin-top: 16px; }
  .main-menu__menu-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
    padding-right: 40px; }
    @media (max-width: 1600px) {
      .main-menu__menu-wrapper {
        padding-right: 0px; } }
    @media (max-width: 1450px) {
      .main-menu__menu-wrapper {
        margin-top: 21px; } }
    @media (max-width: 1250px) {
      .main-menu__menu-wrapper {
        flex-wrap: wrap;
        padding-right: 40px;
        margin-top: 33px; } }
    @media (max-width: 800px) {
      .main-menu__menu-wrapper {
        padding-right: 0px; } }
  .main-menu__column {
    width: 364px; }
    @media (max-width: 1900px) {
      .main-menu__column {
        width: 355px; } }
    @media (max-width: 1600px) {
      .main-menu__column {
        width: 285px; } }
    @media (max-width: 1450px) {
      .main-menu__column {
        width: 24%; } }
    @media (max-width: 1250px) {
      .main-menu__column {
        width: 48%; } }
    @media (max-width: 576px) {
      .main-menu__column {
        width: 100%; } }
  .main-menu__product-item {
    height: 72px;
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 62px; }
    @media (max-width: 1600px) {
      .main-menu__product-item {
        margin-bottom: 19px; } }
    @media (max-width: 1450px) {
      .main-menu__product-item {
        height: 60px;
        margin-bottom: 17px; } }
    @media (max-width: 1250px) {
      .main-menu__product-item {
        height: 72px;
        margin-bottom: 19px; } }
    .main-menu__product-item.with-list {
      height: auto;
      padding: 27px 0px 27px 24px;
      box-sizing: border-box;
      display: block; }
      @media (max-width: 1450px) {
        .main-menu__product-item.with-list {
          padding-top: 20px;
          padding-bottom: 22px;
          padding-left: 20px; } }
      @media (max-width: 1250px) {
        .main-menu__product-item.with-list {
          padding-top: 24px;
          padding-left: 24px;
          padding-bottom: 30px; } }
    .main-menu__product-item.in-dev {
      border: none;
      background: #f2f2f2; }
  .main-menu__in-dev-note {
    display: none;
    background: url(assets/images/main-menu/Union.svg) no-repeat;
    width: 176px;
    height: 65px;
    position: absolute;
    right: 12px;
    top: -28px; }
    @media (max-width: 1450px) {
      .main-menu__in-dev-note {
        top: -40px; } }
    @media (max-width: 1250px) {
      .main-menu__in-dev-note {
        top: -35px; } }
    .main-menu__in-dev-note p {
      color: #fff;
      font-size: 12px;
      position: absolute;
      top: 22px;
      left: 27px; }
  .main-menu__accordion {
    margin: 0px; }
    .main-menu__accordion ul {
      display: none;
      margin-top: 18px;
      margin-left: 43px; }
    .main-menu__accordion a {
      display: inline-block;
      font-size: 15px;
      color: #000;
      opacity: 1;
      text-decoration: none;
      margin-bottom: 13px;
      transition: 0.125s;
      line-height: 20px; }
      .main-menu__accordion a:hover {
        color: #FE5F55;
        font-weight: 400; }
  .main-menu .accordion-item {
    text-decoration: none;
    cursor: pointer;
    color: #000;
    line-height: 15px;
    margin-top: 16px;
    margin-bottom: 0px;
    opacity: 1;
    font-size: 15px; }
    @media (max-width: 1450px) {
      .main-menu .accordion-item {
        margin-top: 10px; } }
    @media (max-width: 1250px) {
      .main-menu .accordion-item {
        margin-top: 16px; } }
    .main-menu .accordion-item::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 21px;
      position: relative;
      top: 7px; }
    .main-menu .accordion-item::after {
      content: "";
      display: inline-block;
      background: url(assets/images/main-menu/Vector-menu-down.svg) no-repeat;
      width: 8px;
      height: 5px;
      margin-left: 7px;
      position: relative;
      bottom: 1px; }
    .main-menu .accordion-item.active::after {
      content: "";
      display: inline-block;
      background: url(assets/images/main-menu/Vector-menu-up.svg) no-repeat;
      width: 8px;
      height: 5px;
      margin-left: 7px;
      position: relative;
      bottom: 1px; }
    .main-menu .accordion-item.ads::before {
      background: url(assets/images/main-menu/Megaphone-menu.svg) no-repeat; }
      @media (max-width: 800px) {
        .main-menu .accordion-item.ads::before {
          background: url(assets/images/Megaphone-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.ads.active {
      color: #bbbcbe; }
      .main-menu .accordion-item.ads.active::before {
        background: url(assets/images/main-menu/Megaphone-red.svg) no-repeat; }
        @media (max-width: 800px) {
          .main-menu .accordion-item.ads.active::before {
            background: url(assets/images/Megaphone-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.site::before {
      background: url(assets/images/main-menu/Wrench-menu.svg) no-repeat; }
      @media (max-width: 800px) {
        .main-menu .accordion-item.site::before {
          background: url(assets/images/Wrench-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.site.active {
      color: #bbbcbe; }
      .main-menu .accordion-item.site.active::before {
        background: url(assets/images/main-menu/Wrench-red.svg) no-repeat; }
        @media (max-width: 800px) {
          .main-menu .accordion-item.site.active::before {
            background: url(assets/images/Wrench-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.smm::before {
      background: url(assets/images/main-menu/ThumbsUp-menu.svg) no-repeat; }
      @media (max-width: 800px) {
        .main-menu .accordion-item.smm::before {
          background: url(assets/images/ThumbsUp-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.smm.active {
      color: #bbbcbe; }
      .main-menu .accordion-item.smm.active::before {
        background: url(assets/images/main-menu/ThumbsUp-red.svg) no-repeat; }
        @media (max-width: 800px) {
          .main-menu .accordion-item.smm.active::before {
            background: url(assets/images/ThumbsUp-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.brand::before {
      background: url(assets/images/main-menu/flyers-menu.svg) no-repeat; }
      @media (max-width: 800px) {
        .main-menu .accordion-item.brand::before {
          background: url(assets/images/flyers-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.brand.active {
      color: #bbbcbe; }
      .main-menu .accordion-item.brand.active::before {
        background: url(assets/images/main-menu/flyers-red.svg) no-repeat; }
        @media (max-width: 800px) {
          .main-menu .accordion-item.brand.active::before {
            background: url(assets/images/flyers-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.video::before {
      background: url(assets/images/main-menu/brand-menu.svg) no-repeat; }
      @media (max-width: 800px) {
        .main-menu .accordion-item.video::before {
          background: url(assets/images/brand-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.video.active {
      color: #bbbcbe; }
      .main-menu .accordion-item.video.active::before {
        background: url(assets/images/main-menu/brand-red.svg) no-repeat; }
        @media (max-width: 800px) {
          .main-menu .accordion-item.video.active::before {
            background: url(assets/images/brand-mobile.svg) no-repeat; } }
    .main-menu .accordion-item.complex::before {
      background: url(assets/images/complex-white.svg) no-repeat; }
      @media (max-width: 1050px) {
        .main-menu .accordion-item.complex::before {
          background: url(assets/images/complex-red.svg.svg) no-repeat; } }
    .main-menu .accordion-item.complex.active {
      color: #696161;
      opacity: 1; }
      .main-menu .accordion-item.complex.active::before {
        background: url(assets/images/complex-red.svg) no-repeat; }
        @media (max-width: 1050px) {
          .main-menu .accordion-item.complex.active::before {
            background: url(assets/images/complex-red.svg) no-repeat; } }
  .main-menu__product-name {
    font-weight: 500;
    color: #000;
    display: block;
    transition: 0.125s;
    line-height: 19px; }
    @media (max-width: 1600px) {
      .main-menu__product-name {
        width: 92%; } }
    @media (max-width: 1450px) {
      .main-menu__product-name {
        font-size: 15px; } }
    @media (max-width: 1250px) {
      .main-menu__product-name {
        font-size: 16px; } }
    .main-menu__product-name.with-icon {
      cursor: pointer; }
      .main-menu__product-name.with-icon::before {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 23px;
        left: 24px; }
        @media (max-width: 1450px) {
          .main-menu__product-name.with-icon::before {
            top: 18px; } }
        @media (max-width: 1250px) {
          .main-menu__product-name.with-icon::before {
            top: 23px; } }
      .main-menu__product-name.with-icon:hover {
        color: #FE5F55; }
    .main-menu__product-name.bloggers::before {
      background: url(assets/images/main-menu/influencer.svg) no-repeat; }
    .main-menu__product-name.freelance::before {
      background: url(assets/images/main-menu/freelance.svg) no-repeat; }
    .main-menu__product-name.online-bank::before {
      background: url(assets/images/main-menu/bank.svg) no-repeat; }
    .main-menu__product-name.cases-product::before {
      background: url(assets/images/main-menu/case.svg) no-repeat; }
    .main-menu__product-name.employees::before {
      background: url(assets/images/main-menu/magnifier.svg) no-repeat; }
    .main-menu__product-name.commercial::before {
      background: url(assets/images/main-menu/search.svg) no-repeat; }
    .main-menu__product-name.supplier::before {
      background: url(assets/images/main-menu/case-study.svg) no-repeat; }
    .main-menu__product-name.business-project::before {
      background: url(assets/images/main-menu/business.svg) no-repeat; }
    .main-menu__product-name.crowdfunding::before {
      background: url(assets/images/main-menu/crowdfunding.svg) no-repeat; }
    .main-menu__product-name.cleaning::before {
      background: url(assets/images/main-menu/delete-ico.svg) no-repeat; }
    .main-menu__product-name.start-business::before {
      background: url(assets/images/main-menu/opportunity.svg) no-repeat; }
    .main-menu__product-name.business-constructor::before {
      background: url(assets/images/main-menu/stairs.svg) no-repeat; }
    .main-menu__product-name.online-accounting::before {
      background: url(assets/images/main-menu/calculator.svg) no-repeat; }
  .main-menu__sub-product {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: #000;
    line-height: 15px;
    margin-top: 25px;
    font-size: 15px;
    position: relative;
    margin-left: 45px;
    transition: 0.125s; }
    @media (max-width: 1450px) {
      .main-menu__sub-product {
        margin-top: 22px;
        width: 75%; } }
    @media (max-width: 1250px) {
      .main-menu__sub-product {
        margin-top: 25px; } }
    .main-menu__sub-product.not-active {
      opacity: 0.5; }
    .main-menu__sub-product:hover {
      color: #FE5F55; }
    .main-menu__sub-product::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: -5px;
      left: -42px; }
    .main-menu__sub-product.mentoring::before {
      background: url(assets/images/main-menu/teacher.svg) no-repeat; }
    .main-menu__sub-product.education-courses::before {
      background: url(assets/images/main-menu/online-learning.svg) no-repeat; }
    .main-menu__sub-product.entrepreneur-inst::before {
      background: url(assets/images/main-menu/case-study.svg) no-repeat; }
    .main-menu__sub-product.knowledge::before {
      background: url(assets/images/main-menu/online-education.svg) no-repeat; }
    .main-menu__sub-product.business-taxi::before {
      background: url(assets/images/main-menu/taxi.svg) no-repeat; }
    .main-menu__sub-product.business-trucking::before {
      background: url(assets/images/main-menu/truck.svg) no-repeat; }
    .main-menu__sub-product.business-avia::before {
      background: url(assets/images/main-menu/airplane.svg) no-repeat; }
    .main-menu__sub-product.car-rent::before {
      background: url(assets/images/main-menu/car-key.svg) no-repeat; }
  .main-menu .main-menu__accordion2 .accordion-item {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: #000;
    line-height: 15px;
    margin-top: 25px;
    font-size: 15px;
    position: relative;
    margin-left: 45px;
    transition: 0.125s; }
    @media (max-width: 1450px) {
      .main-menu .main-menu__accordion2 .accordion-item {
        margin-top: 22px;
        width: 75%; } }
    @media (max-width: 1250px) {
      .main-menu .main-menu__accordion2 .accordion-item {
        margin-top: 25px; } }
    .main-menu .main-menu__accordion2 .accordion-item.not-active {
      opacity: 0.5; }
    .main-menu .main-menu__accordion2 .accordion-item:hover {
      color: #FE5F55; }
    .main-menu .main-menu__accordion2 .accordion-item::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: -5px;
      left: -42px; }
    .main-menu .main-menu__accordion2 .accordion-item:after {
      display: none; }
  .main-menu .crm-system::before {
    background: url(assets/images/main-menu/internet.svg) no-repeat; }
  .main-menu .telephony::before {
    background: url(assets/images/main-menu/telefoniya.svg) no-repeat; }
  .main-menu .call-tracking::before {
    background: url(assets/images/main-menu/calltracking.svg) no-repeat; }
  .main-menu .chatbot::before {
    background: url(assets/images/main-menu/chatbot.svg) no-repeat; }
  .main-menu .online-pay::before {
    background: url(assets/images/main-menu/online-pay.svg) no-repeat; }
  .main-menu .site-constructor::before {
    background: url(assets/images/main-menu/site-constructor.svg) no-repeat; }
  .main-menu .analytics::before {
    background: url(assets/images/main-menu/analytics.svg) no-repeat; }

/*
 * Основные точки перехода
 * Идем от Десктопа к мобильникам
 */
/**
  * Планшеты горизонтальные
 */
.modal .btn {
  width: 100%;
  margin-top: 20px; }

.modal .check {
  display: flex; }
  .modal .check .form-checkbox-light {
    width: 15px;
    height: 15px;
    position: relative;
    margin-left: 9px;
    background: #000000; }
    .modal .check .form-checkbox-light.validate-er {
      background: #FE5F55; }
    .modal .check .form-checkbox-light > label {
      width: 11px;
      height: 11px;
      cursor: pointer;
      position: absolute;
      left: 2px;
      top: 2px;
      background: #ffffff; }
      .modal .check .form-checkbox-light > label:after {
        content: "";
        width: 7px;
        height: 4px;
        position: absolute;
        top: 1px;
        left: 1px;
        border: 3px solid #000;
        border-top: none;
        border-right: none;
        opacity: 0;
        transform: rotate(-45deg); }
    .modal .check .form-checkbox-light > input[type="checkbox"] {
      visibility: hidden; }
      .modal .check .form-checkbox-light > input[type="checkbox"]:checked + label:after {
        opacity: 1; }
  .modal .check span {
    color: #000000;
    font-size: 13px;
    margin-left: 11px;
    line-height: 15px; }

.modal .validate-er {
  border: 1px solid #FE5F55; }

.modal .validate-msg {
  font-size: 15px;
  line-height: 32px;
  letter-spacing: .01em;
  margin-top: -15px;
  margin-bottom: 13px;
  margin-left: 0px;
  color: #FE5F55; }

.modal .modal-success {
  display: none;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
  /* or 22px */ }
  .modal .modal-success .btn {
    width: 116px; }

.modal .modal-text {
  font-size: 16px;
  font-family: 'Roboto';
  line-height: 24px;
  text-align: center; }

.modal-success {
  display: none;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
  /* or 22px */ }
  .modal-success .btn {
    width: 116px; }

#video-modal .modal-wrapper {
  width: 960px;
  height: 632px;
  top: 13vh; }
  @media (max-width: 800px) {
    #video-modal .modal-wrapper {
      width: 96%;
      height: 56vw;
      padding: 20px;
      top: 40vh; } }
  @media (max-width: 576px) {
    #video-modal .modal-wrapper {
      width: 96%;
      height: 56vw;
      padding: 20px;
      top: 40vh;
      padding: 20px; } }

@media (max-width: 576px) {
  #video-modal .modal-header {
    display: none; } }

.youtube-btn {
  width: 60px;
  height: 44px;
  background: url(assets/images/youtube-btn.svg);
  background-size: cover;
  display: block;
  position: absolute;
  left: 38%;
  top: 33%; }
  @media (max-width: 800px) {
    .youtube-btn {
      width: 34px;
      height: 26px; } }

#spasibo .modal-success {
  display: block; }

#spasibo .btn {
  margin-top: 20px; }

/**
 * Модалка формы оплаты
 */
@media (max-width: 576px) {
  .tarif-modal .modal-wrapper {
    width: 96%;
    height: 87Vh;
    overflow-y: auto; } }

.tarif-modal label {
  width: 100%;
  display: block; }

.tarif-modal__price {
  margin-top: 10px;
  text-align: center; }

.tarif-modal__dogovor {
  margin-top: 10px;
  text-align: center;
  width: 100%;
  display: block; }

.tarif-modal__type-pay {
  margin-top: 20px; }

.not-found-page {
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 49px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  margin-top: 60px;
  width: 900px;
  text-align: center; }
  .not-found-page p {
    font-size: 100px;
    margin: 50px 0px 50px 0px; }
  .not-found-page a {
    color: #FE5F55;
    font-size: 15px; }
    .not-found-page a:hover {
      color: #ff5443;
      font-weight: 500; }

/**
 * Поиск страница Search
 */
.search-page {
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 49px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  margin-top: 60px;
  width: 900px;
  text-align: center; }
  .search-page p {
    font-size: 100px;
    margin: 50px 0px 50px 0px; }
  .search-page a {
    color: #FE5F55;
    font-size: 15px; }
    .search-page a:hover {
      color: #ff5443;
      font-weight: 500; }
